import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { getConfig, validateToken } from "../config";
import Modal from './modales/ModalTelefonoDuplicado';
import './css/Formulario.css';
import Loading from './Loading';
import jwt_decode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { PatternFormat } from "react-number-format";


function FormularioTarjetaFisica(user) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { getAccessTokenSilently } = useAuth0();


    //Get properties from .json
    const config = getConfig();


    const [telephone, setTelephone] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [errTelephone, setErrTelephone] = useState(true);
    const handleChangeTelephone = ({ target: { value } }) => {
        validateForm(value);
        setTelephone(value);
    };

    //Manejo de modal
    const [modal, setModal] = useState(false);

    const [error, setError] = useState('Ocurrió un error, intenta más tarde.');
    const [showError, setShowError] = useState(false);


    const handleKeyDown = (event) => {
        const isValid = validateForm(telephone);
        if (event.key === 'Enter' && isValid) {
            handlesetSubmitChange(event);
        }
    };


    const handlesetSubmitChange = async (event) => {
        event.preventDefault();
        setLoading(true);
        setShowError(false);

        //Enviar OTP

        let data = JSON.stringify({
            "client_id": config.id,
            "connection": "sms",
            "phone_number": `+52${cc_format(telephone).toString().replaceAll(' ', '')}`,
            "send": "code",
            "authParams": { // any authentication parameters that you would like to add
                "scope": "openid",     // used when asking for a magic link
                "redirect_uri": window.location.origin,  // used when asking for a magic link, or from the custom login page
                "response_type": "idToken"
            }
        });

        let axios_config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${config.domain}/passwordless/start`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(axios_config)
            .then((response) => {
                setLoading(false);
                navigate(`/confirma-telefono?${searchParams.toString()}`, {
                    state: {
                        telephone: cc_format(telephone).toString().replaceAll(' ', ''),
                        status: response.status
                    }
                });
            })
            .catch((error) => {
                setLoading(false);
                if (JSON.stringify(error.response.data.error == 'sms_provider_error')) {
                    setError('El número ingresado es incorrecto.');
                    setShowError(true);
                } else {
                    setError('Ocurrió un error, intenta más tarde.');
                    setShowError(true);
                }
            });

    }


    function cc_format(value) {
        const v = value
            .replace(/\s+/g, "")
            .replace(/[^0-9]/gi, "")
            .substr(0, 10);
        const parts = [];

        for (let i = 0; i < v.length; i += 3) {
            parts.push(v.substr(i, 3));
        }

        return parts.length > 1 ? parts.join(" ") : value;
    }


    const validateForm = (value) => {
        setErrTelephone(false);

        if (cc_format(value).length < 12) {
            setErrTelephone(true);
            return false;
        }
        return true;
    }

    //Valida los tres intentos ---------------------------------------------------------------

    useEffect(() => {
        var attempts_otp;

        try {
            getToken();


            var decoded = jwt_decode(searchParams.get('session_token'));
            const platform = decoded.platform;
            window.localStorage.setItem('platform', platform);

            attempts_otp = window.localStorage.getItem('attempts_otp') != 'undefined' ? window.localStorage.getItem('attempts_otp') : 3;
            if (attempts_otp == 0) {
                navigate(`/confirma-telefono?${searchParams.toString()}`, {
                    state: {
                        telephone: cc_format(telephone).toString().replaceAll(' ', ''),
                        status: 'sucess'
                    }
                });
            }


        } catch (e) {
        }

    }, []);


    const getToken = async () => {
        var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

        if (token == null) {
            const token = await getAccessTokenSilently({
                audience: config.audience
            });
            window.localStorage.setItem('token', token);
        } else {
            const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
            //console.log(isTokenValid);
            if (!isTokenValid) {

                const token = await getAccessTokenSilently({
                    audience: config.audience
                });
                window.localStorage.setItem('token', token);
            }
        }
    }



    return (
        <>
            {!isLoading && (
                <form>
                    <h5 style={{ color: '#ffffff' }}>Hola, {user.given_name}</h5>
                    <p style={{ color: '#ffffff' }}>Para continuar ingresa tu teléfono celular.</p>
                    <div className="form-item">
                        <div className="col text-left">
                            <label for="email" className="form-label labelFont" style={{padding: '10px 0px'}}>Celular</label>
                            <div className="input-group">
                                <span id="toggle_celular" className="input-group-addon" style={{padding: '13px 10px'}}>+52</span>
                                <PatternFormat format="## #### ####" allowEmptyFormatting mask=""
                                    inputMode='numeric'
                                    pattern='[0-9]{10}'
                                    className="form-control telephone"
                                    style={{height: '45px'}}
                                    value={telephone} onChange={handleChangeTelephone} onKeyDown={handleKeyDown} />
                                {/*<input type="text" inputMode='numeric' className="form-control telephone" value={cc_format(telephone)} onChange={handleChangeTelephone} />*/}
                            </div>
                            {showError && (<span className="error">{error}</span>)}
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev" id="btn-next" type='button' onClick={handlesetSubmitChange} disabled={errTelephone}>Continuar</button>
                        </div>
                    </div>
                </form>
            )}
            {isLoading && (<Loading />)}

            <Modal showModal={modal} setModal={setModal} tel={telephone} />

        </>
    );
}




export default FormularioTarjetaFisica;

