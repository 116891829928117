import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
//import './css/FailedDatos.css';
import './css/Navbar.css';


function FailedAttemps() {

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();



    var attempts_vinculacion;
    try {
        attempts_vinculacion = window.localStorage.getItem('attempts_vinculacion') != undefined ? window.localStorage.getItem('attempts_vinculacion') : 3;
    } catch (e) {
    }
    const [attemps, setAttemps] = useState(attempts_vinculacion);

    const handleRetry = (event) => {
        event.preventDefault();
        setAttemps(attemps - 1);
        window.localStorage.setItem('attempts_vinculacion', attemps - 1);
        navigate(`/vinculacion?${searchParams.toString()}`, {
            state: {
                name: location.state.name, surname: location.state.surname, birth_date: location.state.birth_date,
                gender: location.state.gender, zip_code: location.state.zip_code, tcc: location.state.tcc, email: location.state.email
            }
        });
    }



    const handleCreateAccount = (event) => {
        event.preventDefault();
        navigate(`/registro?${searchParams.toString()}`, {
            state: {
                name: location.state.name, surname: location.state.surname, gender: location.state.gender, zip_code: location.state.zip_code
            }
        });
    }

    //Valida los tres intentos ---------------------------------------------------------------

    useEffect(() => {
        var attempts_vinculacion;
        try {
            attempts_vinculacion = window.localStorage.getItem('attempts_vinculacion') != undefined ? window.localStorage.getItem('attempts_vinculacion') : 3;
            if (attempts_vinculacion == 0) {
                setAttemps(0);
                navigate(`/error-attemps-limit?${searchParams.toString()}`);
            }
        } catch (e) {
        }

    }, []);



    const { isAuthenticated, user } = useAuth0();


    if (!isAuthenticated) {
        return <div>Access denied</div>;
    }

    return (
        <>
            <Container className='navbar' fluid>
                <Row>
                    <Col> <img className='logo' /></Col>
                </Row>
            </Container>

            <div className="col-xs-5 col-sm-5 col-sm-offset-5 cards">
                <div className="card gray">
                    <img className='logo-club' />
                    <h5 className='warning'>¡Ups!</h5>
                </div>
                <div className="card contenido">
                    <h4 className='title'>Lo sentimos</h4>
                    <p className='description-left'>Desafortunadamente, los datos de tu cuenta no coinciden con los que tenemos registrados.</p>
                    <p className='description-left'>Puedes Intentar de nuevo con otro email o número de tarjeta Club Cinépolis.</p>
                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev-white" id="btn-next" type='button' onClick={handleRetry}>Intentar de nuevo</button>
                        </div>
                    </div>
                    <p className='description-left'>&nbsp;</p>
                    <hr />
                    <h4 className='subtitle'>También puedes crear una cuenta nueva </h4>
                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev" id="btn-next" type='button' onClick={handleCreateAccount}>Crear cuenta nueva</button>
                        </div>
                    </div>
                    <p className='description-left'>Si creas una cuenta nueva no se desactivará tu tarjeta física actual, pero te recomendamos usar la digital en tus nuevas transacciones.</p>
                    <p className='description-left'>Para crear una cuenta nueva tendrás que volver a introducir tus datos desde el inicio.</p>
                </div>
            </div>

        </>
    );
}

export default withAuthenticationRequired(FailedAttemps, {
    onRedirecting: () => <Loading />,
});

