import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Terminos from './Terms';
import Loading from './Loading';
import { getConfig, validateToken } from "../config";
import './css/Formulario.css';
import './css/Switch.css';

function FormularioRegistro(user) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const { audience } = getConfig();

    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [user_id, setUserID] = useState();
    const [strategy, setStrategy] = useState();
    const { getAccessTokenSilently } = useAuth0();




    // Validar si el login se hizo desde red social o no
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const jwt = queryParameters.get("session_token");
        var decoded = jwt_decode(jwt);
        setEmail(decoded.email)
        setUserID(decoded.user_id)
        setStrategy(decoded.strategy)
    }, []);

    const handlesetSubmitChange = async (event) => {
        event.preventDefault();

        const isValid = await validateForm();


        if (isValid == true) {
            setLoading(true);
            let data = JSON.stringify({
                "nombre": `${location.state.nombre}`,
                "paterno": `${location.state.paterno}`,
                "materno": `${location.state.materno}`,
                "curp": `${location.state.curp}`,
                "rfc": `${location.state.rfc}`,
                "folio": `${location.state.folio}`,
                "birthdate": `${location.state.birthdate.replaceAll(' / ', '-')}`,
                "country": `${location.state.country}`,
                "civil": `${location.state.civil}`,
                "gender": `${location.state.gender}`,
                "password": `${location.state.password}`,
                "calle": `${location.state.calle}`,
                "exterior": `${location.state.exterior}`,
                "interior": `${location.state.interior}`,
                "referencia": `${location.state.referencia}`,
                "strategy": `${location.state.strategy}`,
                "zipcode": `${location.state.zipcode}`,
                "colonia": `${location.state.colonia}`,
                "municipio": `${location.state.municipio}`,
                "estado": `${location.state.estado}`,
                "user_id": user_id,
                "email": email
            });


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://dcmacp1mej.execute-api.us-east-1.amazonaws.com/api/create?${searchParams.toString()}}`,
                headers: {
                    'content-type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    navigate(`/cuenta-creada?${searchParams.toString()}`, {
                        state: {
                            nombre: `${location.state.nombre}`, paterno: `${location.state.paterno}`, materno: `${location.state.materno}`, curp: `${location.state.curp}`,
                            rfc: `${location.state.rfc}`, folio: `${location.state.folio}`, birthdate: `${location.state.birthdate}`, country: `${location.state.country}`,
                            civil: `${location.state.civil}`, gender: `${location.state.gender}`, password: `${location.state.password}`, calle: `${location.state.calle}`,
                            exterior: `${location.state.exterior}`, interior: `${location.state.interior}`, referencia: `${location.state.referencia}`, strategy: `${location.state.strategy}`,
                            zipcode: `${location.state.zipcode}`, colonia: `${location.state.colonia}`, municipio: `${location.state.municipio}`, estado: `${location.state.estado}`
                        }
                    });
                })
                .catch((error) => {
                    console.log(error);
                    navigate(`/error?${searchParams.toString()}`);
                });

            /*
            navigate(`/error?${searchParams.toString()}`, {
                state: {
                    name: name, surname: surname, email: user.email, user_name: user.sub, birth_date: fecha_nacimiento,
                    gender: gender, zip_code: postalCode, is_social: isSocial, password: firstPass, proceso: "create", nav_title: "Cuenta nueva exitosa"
                }
            });
            */

        } else {
            setLoading(false);
        }
    }




    const getToken = async () => {
        var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

        if (token == null) {
            const token = await getAccessTokenSilently({
                audience: audience
            });
            window.localStorage.setItem('token', token);
        } else {
            const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
            //console.log(isTokenValid);
            if (!isTokenValid) {
                const token = await getAccessTokenSilently({
                    audience: audience
                });
                window.localStorage.setItem('token', token);
            }
        }
    }





    const validateForm = () => {
        return true;
    }


    return (
        <>
            {!isLoading && (
                <form style={{ height: '100%' }}>
                    <div className="form-item">
                        <div className="col text-left">
                            <h5>Firma de documentos</h5>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="col text-left">
                            <span style={{ width: '100%', maxWidth: '80%', fontSize: '14px' }}>
                                Al firmar <b>EL CLIENTE</b> acepta que CAME (Consejo de Asistencia al Microemprendedor, S.A. de C.V. S.F.P.) hizo de su conocimiento los términos
                                y condiciones que se enlistan, los que suscriben de conformidad para todos los efectos legales, acepto la firma.
                            </span>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <div>
                                <span className='prevencion-check' style={{
                                    left: '0%', top: "6px", float: 'right', position: 'absolute', display: 'inline-block',
                                    background: '#b8be14', border: '#b8be14', width: '10px', minWidth: '10px', maxWidth: '10px', maxHeight: '10px',
                                    borderRadius: '50px', color: '#fff'
                                }}>.</span>
                                <a href='' style={{
                                    fontSize: '14px',
                                    marginLeft: '30px'
                                }}>Aviso de privacidad</a>
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <div>
                                <span className='prevencion-check' style={{
                                    left: '0%', top: "6px", float: 'right', position: 'absolute', display: 'inline-block',
                                    background: '#b8be14', border: '#b8be14', width: '10px', minWidth: '10px', maxWidth: '10px', maxHeight: '10px',
                                    borderRadius: '50px', color: '#b8be14'
                                }}>.</span>
                                <a href='' style={{
                                    fontSize: '14px',
                                    marginLeft: '30px'
                                }}>COF Depósito Digital</a>
                            </div>
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <div>
                                <span className='prevencion-check' style={{
                                    left: '0%', top: "6px", float: 'right', position: 'absolute', display: 'inline-block',
                                    background: '#b8be14', border: '#b8be14', width: '10px', minWidth: '10px', maxWidth: '10px', maxHeight: '10px',
                                    borderRadius: '50px', color: '#b8be14'
                                }}>.</span>
                                <a href='' style={{
                                    fontSize: '14px',
                                    marginLeft: '30px'
                                }}>Contrato</a>
                            </div>
                        </div>
                    </div>

                    <div className="form-item" style={{ marginTop: '80%' }}>
                        <div className="col text-left">
                            <button className="prev" id="btn-next" type='button' onClick={handlesetSubmitChange} >Aceptar</button>
                        </div>
                    </div>
                </form>
            )}
            {isLoading && (<Loading />)}
        </>
    );
}

export default FormularioRegistro;

