import React, { useState } from "react";
import './css/Navbar.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { useNavigate } from "react-router-dom";
import Modal from "./modales/ModalAbortOperation";
import { isDesktop } from 'react-device-detect';

const Navbar = (props) => {
    const { title, cls1, cls2, cls3 } = props;

    const navigate = useNavigate();
    const [show, setShowModal] = useState(false);
    console.log(isDesktop);

    return (
        <>
            <Container className='navbar' fluid>
                <Row>
                    <Col> <img className='logo' /></Col>
                </Row>
                {/**
                <Container className='navbar_menubar'>
                    <Row>
                        {isDesktop && (<Col className='navbar_menubar_first'><IoIosArrowBack size={20} onClick={() => navigate(-1)} /></Col>)}
                        <Col className='navbar_menubar_middle' xs={6} style={!isDesktop ? { marginLeft: '20px' } : {}}>{title}</Col>
                        <Col className='navbar_menubar_last'><IoMdClose size={20} onClick={() => { setShowModal(!show) }} /></Col>
                    </Row>
                    <Row>
                        <div >
                            <hr className={cls1} /><hr className={cls2} /><hr className={cls3} />
                        </div>
                    </Row>
                    <Row>
                    </Row>
                </Container>
                 */}
            </Container>
            <Modal showModal={show} setModal={setShowModal} />
        </>
    )
}

export default Navbar