import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Autocomplete from "react-google-autocomplete";
import Loading from './Loading';
import { getConfig } from "../config";
import './css/Formulario.css';
import './css/QR.css';
import './css/Switch.css';

function FormularioRegistro(user) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const { audience, service } = getConfig();

    const [isLoading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const [street, setStreet] = useState();
    const handleStreetChange = ({ target: { value } }) => {
        setStreet(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));
        console.log("🚀 ~ file: FormularioRegistro.js:25 ~ handleStreetChange ~ handleStreetChange:", colonias_options)

    }

    const [exterior, setExterior] = useState();
    const handleExteriorChange = ({ target: { value } }) => setExterior(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [interior, setInterior] = useState();
    const handleInteriorChange = ({ target: { value } }) => setInterior(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [referencia, setReferencia] = useState();
    const handleReferenciaChange = ({ target: { value } }) => setReferencia(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    }

    const [postalCode, setPostalCode] = useState('');
    const [errZipcode, setErrZipcode] = useState(false);
    const handlePostalChange = ({ target: { value } }) => {
        setPostalCode(value.replace(/[^0-9]/gi, ''));
        setErrZipcode(false);
        
        if (value.length == 5) {
            getCityAndCountry(value);
        }
        
    };

    const [colonias_options, setColonias_Options] = useState([{
        value: 'Seleccione', text: 'Seleccione'
    }]);

    const [colonia, setColonia] = useState('');
    const handleColoniaChange = ({ target: { value } }) => setColonia(value);


    const [municipio, setMunicipio] = useState('');
    const handleMunicipioChange = ({ target: { value } }) => setMunicipio(value);


    const [estado, setEstado] = useState('');
    const handleEstadoChange = ({ target: { value } }) => setEstado(value);


    // Validar si el login se hizo desde red social o no
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const jwt = queryParameters.get("session_token");
        var decoded = jwt_decode(jwt);
        setStreet(decoded.address.split('\n')[0])

        console.log(location.state);
    }, []);

    const handlesetSubmitChange = async (event) => {
        event.preventDefault();

        const isValid = await validateForm();


        if (isValid == true) {
            setLoading(true);
            navigate(`/vincula-tcc?${searchParams.toString()}`, {
                state: {
                    nombre: `${location.state.nombre}`, paterno: `${location.state.paterno}`, materno: `${location.state.materno}`, curp: `${location.state.curp}`,
                    rfc: `${location.state.rfc}`, folio: `${location.state.folio}`, birthdate: `${location.state.birthdate}`, country: `${location.state.country}`,
                    civil: `${location.state.civil}`, gender: `${location.state.gender}`, password: `${location.state.password}`, calle: street, exterior: exterior,
                    interior: interior, referencia: referencia, zipcode: postalCode, colonia: colonia, municipio: municipio, estado: estado, strategy: `${location.state.strategy}`
                }
            });
        } else {
            setLoading(false);
        }
    }

    //Obtener ciudad y país en base al codigo postal
    const getCityAndCountry = async (zipCode) => {
        setLoading(true);

        let config_axios = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://app.zipcodebase.com/api/v1/search?apikey=31e31990-274c-11ee-baba-57a01e20a5e1&codes=${zipCode}&country=mx`,
            headers: {}
        };

        await axios.request(config_axios)
            .then(async (response) => {
                setLoading(false);
                const zipCode_res = response.data.results[`${zipCode}`];
                const municipio = response.data.results[`${zipCode}`][0].province;
                const estado = response.data.results[`${zipCode}`][0].state;
                const options = [];

                setMunicipio(municipio);
                setEstado(estado);

                zipCode_res.forEach(element => {
                    //console.log("🚀 ~ file: FormularioRegistro.js:137 ~ .then ~ element:", element.city)
                    options.push({ value: `${element.city}`, text: `${element.city}` })
                });
                console.log("🚀 ~ file: FormularioRegistro.js:110 ~ .then ~ options:", options)
                setColonias_Options(options)
                //(options);
            })
            .catch((error) => {
                console.log("🚀 ~ file: FormularioRegistro.js:119 ~ getCityAndCountry ~ error:", error)
                setLoading(false);


            });
    };




    //Format Date
    const checkValue = (str, max) => {
        if (str.charAt(0) !== '0' || str == '00') {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
        };
        return str;
    };



    //On blur Date input
    const handleObBlur = ({ target: { value } }) => {
        var input = value;
        var values = input.split('/').map(function (v, i) {
            return v.replace(/\D/g, '')
        });
        var output = '';

        if (values.length == 3) {
            var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
            var month = parseInt(values[0]) - 1;
            var day = parseInt(values[1]);
            var d = new Date(year, month, day);
            if (!isNaN(d)) {
                var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
                output = dates.map(function (v) {
                    v = v.toString();
                    return v.length == 1 ? '0' + v : v;
                }).join(' / ');
            };
        };
        value = output;
    }




    const validateForm = () => {

        const leng_cp = postalCode != undefined ? postalCode.length : 0;
        if (leng_cp < 5) {
            setErrZipcode(true);
            return false;
        }

        return true;
    }


    return (
        <>
            {!isLoading && (
                <form>
                    <div className="form-item">
                        <div className="col text-left">
                            <h5>¿Dónde vives?</h5>
                        </div>
                    </div>

                    {/** Maps */}
                    <div className="form-item">
                        <div className="col text-left">
                            <label for="email" className="form-label labelFont">Domicilio</label>

                            <Autocomplete
                                className="form-control"
                                apiKey={"AIzaSyAwYiFvrVyiDQtBphnOr9l6ztB59ge5lsc"}
                                onPlaceSelected={async (place) => {
                                    console.log(place.address_components);
                                    setExterior(place.address_components[0].long_name)
                                    setStreet(place.address_components[1].long_name);
                                    const zipCode = place.address_components[6].long_name;
                                    setPostalCode(zipCode)

                                    if (zipCode.length == 5) {
                                        await getCityAndCountry(zipCode);
                                    }

                                    setColonia(place.address_components[2].long_name)
                                    setMunicipio(place.address_components[3].long_name)
                                    setEstado(place.address_components[4].long_name)
                                }}
                                options={{
                                    types: ["address"]
                                }}
                            />
                        </div>
                    </div>



                    {/**  */}
                    <div className="form-item">
                        <div className="col text-left">
                            <label for="email" className="form-label labelFont">Calle</label>
                            <input type="text" className="form-control" value={street} onChange={handleStreetChange} />
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">No. Ext.</label>
                            <input type="text" className="form-control" value={exterior} onChange={handleExteriorChange} />
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">No. Int.</label>
                            <input type="text" className="form-control" value={interior} onChange={handleInteriorChange} />
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Referencia adicional (opcional)</label>
                            <input type="text" className="form-control" value={referencia} onChange={handleReferenciaChange} />
                        </div>
                    </div>



                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Código postal</label>
                            <input type='text' inputMode='numeric' pattern='[0-9]{6}' max={999999} maxLength={5}
                                className="form-control" value={postalCode} onChange={handlePostalChange}  />
                            {errZipcode && (<span className="error">El Código Postal que ingresaste es incorrecto.</span>)}
                        </div>
                    </div>



                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Colonia</label>
                            <select data-width="100%" className="form-control selector-wrapper" value={colonia} onChange={handleColoniaChange} >
                                {colonias_options.map(options => (
                                    <option key={options.value} value={options.value}>
                                        {options.text}
                                    </option>
                                ))}
                            </select>
                            <span className="error" id="error-messages1"></span>
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Delegación o Municipio</label>
                            <input type="text" className="form-control" value={municipio} onChange={handleMunicipioChange} />
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Estado</label>
                            <input type="text" className="form-control" value={estado} onChange={handleEstadoChange} />
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: 'none' }}
                            />
                            <button className="prev-purple" id="btn-file" type='button' onClick={() => { hiddenFileInput.current.click(); }}>Subir comprobante</button>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev-purple" id="btn-picture" type='button'>Tomar fotografía</button>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev" id="btn-next" type='button' onClick={handlesetSubmitChange} disabled={
                                !(
                                    (street) &&
                                    (exterior) &&
                                    (typeof postalCode !== 'undefined' ? postalCode.length >= 5 ? postalCode : '' : '')
                                )
                            }>Siguiente</button>
                        </div>
                    </div>
                </form>
            )}
            {isLoading && (<Loading />)}

        </>
    );
}

export default FormularioRegistro;

