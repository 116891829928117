import React, { useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import { getConfig, validateToken } from "../config";

import './css/FailedIntent.css';
import './css/Navbar.css';


function FailedAttemps() {

  
    const [searchParams] = useSearchParams();

    //Get properties from .json
    const config = getConfig();

    const [isLoading, setLoading] = useState(false);





    const handlesetSubmitChange = async (event) => {
        event.preventDefault();
        setLoading(true);
        let data = JSON.stringify({
            "status": "complete"
        });

        let config_axios = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://dcmacp1mej.execute-api.us-east-1.amazonaws.com/api/sign?${searchParams.toString()}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config_axios)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                const session_token = response.data.session_token;
                const state = response.data.state;
                window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${session_token}`);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const { isAuthenticated } = useAuth0();


    if (!isAuthenticated) {
        return <div>Access denied</div>;
    }

    return (
        <>
            {!isLoading && (
                <>
                    <Container className='navbar' fluid>
                        <Row>
                            <Col> <img className='logo' /></Col>
                        </Row>
                    </Container>

                    <div className="col-xs-5 col-sm-5 col-sm-offset-5 cards">
                        <div className="card gray">
                            <img className='logo-club' />
                            <h5 className='warning'> ¡Ups!</h5>
                        </div>
                        <div className="card contenido">
                            <h4 className='title'>Lo sentimos</h4>
                            <p className='description'>Ha ocurrido un error al guardar tus datos en el proceso de registro.</p>

                            <div className="form-item">
                                <div className="col text-left">
                                    <button className="prev" id="btn-next" type='button' onClick={handlesetSubmitChange}>Ir al inicio</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {isLoading && (<Loading />)}
        </>
    );
}

export default withAuthenticationRequired(FailedAttemps, {
    onRedirecting: () => <Loading />,
});

