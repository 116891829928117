import React, { useState } from 'react';
import '../css/Modales.css'
import { IoMdClose } from 'react-icons/io';
import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getConfig, validateToken } from "../../config";
import axios from "axios";
import Loading from '../Loading';
import { useAuth0 } from "@auth0/auth0-react";


function Modal({ showModal, setModal }) {
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  //Get properties from .json
  const config = getConfig();

  const handleContinuar = async () => {
    setLoading(true);
    //Regresar a la callback, se deniega el acceso y se hace un continue
    const state = searchParams.get('state');
    const session_token = searchParams.get('session_token');
    var decoded = jwt_decode(session_token);
    const user_id = decoded.sub;


    let data = JSON.stringify({
      "status": "aborted"
    });

    let config_axios = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://dcmacp1mej.execute-api.us-east-1.amazonaws.com/api/sign?${searchParams.toString()}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config_axios)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const session_token = response.data.session_token;
        const state = response.data.state;
        window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${session_token}`);
      })
      .catch((error) => {
        console.log(error);
      });

  }

  return (
    <>
      {!isLoading && (
        <>
          {showModal &&
            <div className='fondo'>
              <div className='modal'>
                <div className='modal-contenido'>
                  <div className='contenido' style={{height: '300px'}}>
                    <h5 className='abort-title'>El código que ingresaste es incorrecto.</h5>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-blue" id="btn-next" type='button' onClick={() => setModal(!showModal)}>Intenta de nuevo</button>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-white" id="btn-next" type='button' onClick={handleContinuar}>Ir al inicio</button>
                      </div>
                    </div>
                  </div>
                  <div className='modal-button' onClick={() => setModal(!showModal)}>
                    <IoMdClose size={20} />
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
      {isLoading && (<Loading />)}
    </>
  );
}

export default Modal;
