import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import clevertap from 'clevertap-web-sdk';
import Terminos from './Terms';
import Loading from './Loading';
import { getConfig, validateToken } from "../config";
import './css/Formulario.css';
import './css/Switch.css';
import './css/Inicio.css';

function FormularioRegistro(user) {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const { audience } = getConfig();

    const [isLoading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const [name, setName] = useState();
    const [errName, setErrName] = useState(false);
    const handleNameChange = ({ target: { value } }) => setName(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [surname, setSurname] = useState();
    const [errLastName, setErrLastName] = useState(false);
    const handleSurnameChange = ({ target: { value } }) => setSurname(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));


    const [seconLastName, setSecondLastName] = useState();
    const [errSecondLastName, setErrSecondLastName] = useState(false);
    const handleSecondLastNameChange = ({ target: { value } }) => setSecondLastName(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [curp, setCurp] = useState();
    const [errCurp, setErrCurp] = useState(false);
    const handleCurpChange = ({ target: { value } }) => setCurp(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [rfc, setRfc] = useState();
    const [errRfc, setErrRfc] = useState(false);
    const handleRfcChange = ({ target: { value } }) => setRfc(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [folio, setFolio] = useState();
    const [errFolio, setErrfolio] = useState(false);
    const handleFolioChange = ({ target: { value } }) => setFolio(value.replace(/[^0-9]/gi, ''));


    const gender_options = [
        { value: 'M', text: 'Masculino' },
        { value: 'F', text: 'Femenino' }
    ];
    const [gender, setGender] = useState(gender_options[0].value);
    const handleGenderChange = ({ target: { value } }) => setGender(value);

    const [birthDate, setBirthDate] = useState('');
    const [errDate, setErrDate] = useState(false);
    const handleDateChange = ({ target: { value } }) => {
        setErrDate(false);
        var input = value;
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('/').map(function (v) {
            return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = checkValue(values[0], 31);
        if (values[1]) values[1] = checkValue(values[1], 12);
        var output = values.map(function (v, i) {
            return v.length == 2 && i < 2 ? v + ' / ' : v;
        });
        value = output.join('').substr(0, 14);
        //Validar mayor de edad
        if (value.length >= 14) {
            let arry_date = value.replaceAll(' ', '').split('/');
            const formatted_date = `${arry_date[2]}/${arry_date[1]}/${arry_date[0]}`;
            const nowDate = new Date();
            const dateUser = new Date(formatted_date);

            let diferencia_fechas = nowDate.getTime() - dateUser.getTime();
            const dias_diff = Math.round(diferencia_fechas / (1000 * 60 * 60 * 24));
            const años = dias_diff / 365;
            if (años < 18) {
                setErrDate(true);
            } else if (años > 100) {
                setBirthDate(value.substr(0, 10));
                return;
            }

        }
        setBirthDate(value);
    }

    const countries_options = [
        { value: 'Mexico', text: 'México' },
        { value: 'USA', text: 'United States of America' }

    ];
    const [country, setCountry] = useState(countries_options[0].value);
    const [errCountry, setErrCountry] = useState(false);
    const handleCountryChange = ({ target: { value } }) => setCountry(value);

    const civil_options = [
        { value: 'Soltero', text: 'Soltero(a)' },
        { value: 'Casado', text: 'Casado(a)' },
        { value: 'Viudo', text: 'Viudo(a)' },
        { value: 'Divorciado', text: 'Divorciado(a)' }
    ];
    const [civil, setCivil] = useState(civil_options[0].value);
    const handleCivilChange = ({ target: { value } }) => setCivil(value);

    const [bg_8characters, setBgbg_8characters] = useState('#7C8BA6');
    const [bg_1upper, setBgbg_1upper] = useState('#7C8BA6');
    const [bg_1lower, setBgbg_1lower] = useState('#7C8BA6');
    const [bg_1digit, setBgbg_1digit] = useState('#7C8BA6');


    const [firstPass, setFirstPass] = useState('');
    const [errPass, setErrPass] = useState(false);
    const handlesetFirstPassChange = ({ target: { value } }) => {
        setFirstPass(value);

        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = value.trim().length;
        const uppercasePassword = uppercaseRegExp.test(value);
        const lowercasePassword = lowercaseRegExp.test(value);
        const digitsPassword = digitsRegExp.test(value);
        const minLengthPassword = minLengthRegExp.test(value);


        if (passwordLength === 0) {
            setBgbg_8characters('#7C8BA6');
            setBgbg_1upper('#7C8BA6');
            setBgbg_1lower('#7C8BA6');
            setBgbg_1digit('#7C8BA6');

        }
        if (uppercasePassword) {
            setBgbg_1upper('#4FC974');
        }
        if (lowercasePassword) {
            setBgbg_1lower('#4FC974');
        }
        if (digitsPassword) {
            setBgbg_1digit('#4FC974');
        }
        if (minLengthPassword) {
            setBgbg_8characters('#4FC974');

        }
        if (!uppercasePassword) {
            setBgbg_1upper('#7C8BA6');
        }
        if (!lowercasePassword) {
            setBgbg_1lower('#7C8BA6');
        }
        if (!digitsPassword) {
            setBgbg_1digit('#7C8BA6');
        }
        if (!minLengthPassword) {
            setBgbg_8characters('#7C8BA6');
        }
    }

    const [isTermsChecked, setCheckedTerms] = useState(false)
    const handleTermsChecked = () => setCheckedTerms(!isTermsChecked)
    const [errTerms, setErrTerms] = useState(false);

    const [strategy, setStrategy] = useState();




    const [showPass1, setShowPass1] = useState(false);
    const [toggleClass, setToggleClass] = useState('eye-slash icon');
    const [typePwd1, setTypePass1] = useState('password');

    const handlePasswordShow = (event) => {
        setTypePass1(showPass1 ? 'password' : 'text');
        setToggleClass(showPass1 ? 'eye-slash icon' : 'eye-open icon');
        setShowPass1(!showPass1);
    }




    //Manejo de modal
    const [terms, setTerms] = useState(false);
    const [url, setUrl] = useState('');


    const [isSocial, setIsSocial] = useState(false);


    // Validar si el login se hizo desde red social o no
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const jwt = queryParameters.get("session_token");
        var decoded = jwt_decode(jwt);

        setIsSocial(decoded.strategy == 'email' ? false : true);
        console.log("🚀 ~ file: Inicio.js:153 ~ useEffect ~ decoded.strategy:", decoded.strategy)
        setStrategy(decoded.strategy);
        setName(decoded.nombre);
        setSurname(decoded.apellido)
        setSecondLastName(decoded.materno)
        setCurp(decoded.curp)
        setRfc(decoded.rfc)
        setFolio(decoded.folio)
        setBirthDate(decoded.birthdate.replaceAll(' ', ' / '))
        setGender(decoded.sexo)

        console.log("User ==:" + JSON.stringify(user));
        clevertap.onUserLogin.push({
            "Site": {
                "Name": `${name}`,            // String
                "Identity": `${user.sub}`,              // String or number
                "Email": `${user.email}`,         // Email address of the user
                "Phone": "",           // Phone (with the country code)
                "Gender": `${gender}`,                     // Can be either M or F
                "DOB": new Date()                 // Date of Birth. Date object
            }
        });


        clevertap.event.push('Onboarding Initiated', {
            "Email": "ing.joseignaciomm@gmail.com",
            "Date": new Date()
        });
    }, []);

    const handlesetSubmitChange = async (event) => {
        event.preventDefault();

        const isValid = await validateForm();


        if (isValid == true) {
            setLoading(true);

            navigate(`/registro?${searchParams.toString()}`, {
                state: {
                    nombre: name, paterno: surname, materno: seconLastName, curp: curp, rfc: rfc, folio: folio,
                    birthdate: birthDate, country: country, civil: civil, gender: gender, password: firstPass, strategy: strategy
                }
            });
        } else {
            setLoading(false);
        }
    }

    //Format Date
    const checkValue = (str, max) => {
        if (str.charAt(0) !== '0' || str == '00') {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
        };
        return str;
    };



    //On blur Date input
    const handleObBlur = ({ target: { value } }) => {
        var input = value;
        var values = input.split('/').map(function (v, i) {
            return v.replace(/\D/g, '')
        });
        var output = '';

        if (values.length == 3) {
            var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
            var month = parseInt(values[0]) - 1;
            var day = parseInt(values[1]);
            var d = new Date(year, month, day);
            if (!isNaN(d)) {
                var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
                output = dates.map(function (v) {
                    v = v.toString();
                    return v.length == 1 ? '0' + v : v;
                }).join(' / ');
            };
        };
        value = output;
    }




    const validateForm = () => {

        setErrName(false);
        setErrLastName(false);
        setErrDate(false);
        setErrCountry(false);
        setErrPass(false);
        setErrTerms(false);

        if (name.trim().length == 0) {
            setErrName(true);
            return false;
        }

        if (surname.trim().length == 0) {
            setErrLastName(true);
            return false;
        }

        if (birthDate == null || birthDate.length < 14) {
            setErrDate(true);
            return false;
        } else if (birthDate == null || birthDate.length >= 14) {
            let arry_date = birthDate.replaceAll(' ', '').split('/');
            const formatted_date = `${arry_date[2]}/${arry_date[1]}/${arry_date[0]}`;
            const nowDate = new Date();
            const dateUser = new Date(formatted_date);

            let diferencia_fechas = nowDate.getTime() - dateUser.getTime();
            const dias_diff = Math.round(diferencia_fechas / (1000 * 60 * 60 * 24));
            const años = dias_diff / 365;
            if (años < 18 || años > 100) {
                setErrDate(true);
                return false;
            }
        }

        if (!isSocial) {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const minLengthRegExp = /.{8,}/;
            const passwordLength = firstPass.trim().length;
            const uppercasePassword = uppercaseRegExp.test(firstPass);
            const lowercasePassword = lowercaseRegExp.test(firstPass);
            const digitsPassword = digitsRegExp.test(firstPass);
            const minLengthPassword = minLengthRegExp.test(firstPass);
            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
                setErrPass(true);

                return false;
            } else if (!uppercasePassword) {
                errMsg = "At least one Uppercase";
                setErrPass(true);

                return false;
            } else if (!lowercasePassword) {
                errMsg = "At least one Lowercase";
                setErrPass(true);

                return false;
            } else if (!digitsPassword) {
                errMsg = "At least one digit";
                setErrPass(true);

                return false;
            } else if (!minLengthPassword) {
                errMsg = "At least minumum 8 characters";
                setErrPass(true);

                return false;
            } else {
                errMsg = "";
            }
        }

        return true;
    }


    return (
        <>
            {!isLoading && (
                <form>
                    <div className="form-item">
                        <div className="col text-left">
                            <h5>Datos generales</h5>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label for="email" className="form-label labelFont">Nombre(s)</label>
                            <input type="text" className="form-control" value={name} onChange={handleNameChange} required autoFocus />
                            {errName && (<span className="error">Este campo es requerido</span>)}
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Apellido Paterno</label>
                            <input type="text" className="form-control" value={surname} onChange={handleSurnameChange} required />
                            {errLastName && (<span className="error">Este campo es requerido</span>)}
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Apellido Materno</label>
                            <input type="text" className="form-control" value={seconLastName} onChange={handleSecondLastNameChange} required />
                            {errSecondLastName && (<span className="error">Este campo es requerido</span>)}
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">CURP</label>
                            <input type="text" className="form-control" value={curp} onChange={handleCurpChange} required />
                            {errCurp && (<span className="error">Este campo es requerido</span>)}
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">RFC</label>
                            <input type="text" className="form-control" value={rfc} onChange={handleRfcChange} required />
                            {errCurp && (<span className="error">Este campo es requerido</span>)}
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Folio de identificación</label>
                            <input type="text" className="form-control" value={folio} onChange={handleFolioChange} required />
                            {errCurp && (<span className="error">Este campo es requerido</span>)}
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Fecha de nacimiento</label>
                            <input type="text" inputMode='numeric' className="form-control" value={birthDate} onChange={handleDateChange} onBlur={handleObBlur} required />
                            {errDate && (<span className="error">Debes ser mayor de edad para registrarte.</span>)}
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">País de nacimiento</label>
                            <select data-width="100%" className="form-control selector-wrapper" value={country} onChange={handleCountryChange} disabled={false}>
                                {countries_options.map(countries_options => (
                                    <option key={countries_options.value} value={countries_options.value}>
                                        {countries_options.text}
                                    </option>
                                ))}
                            </select>
                            <span className="error" id="error-messages1"></span>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Estado civil</label>
                            <select data-width="100%" className="form-control selector-wrapper" value={civil} onChange={handleCivilChange} >
                                {civil_options.map(civil_options => (
                                    <option key={civil_options.value} value={civil_options.value}>
                                        {civil_options.text}
                                    </option>
                                ))}
                            </select>
                            <span className="error" id="error-messages1"></span>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Género</label>
                            <select data-width="100%" className="form-control selector-wrapper" value={gender} onChange={handleGenderChange} >
                                {gender_options.map(gender_options => (
                                    <option key={gender_options.value} value={gender_options.value}>
                                        {gender_options.text}
                                    </option>
                                ))}
                            </select>
                            <span className="error" id="error-messages1"></span>
                        </div>
                    </div>

                    {!isSocial && (
                        <div className="form-item">
                            <div className="col text-left">
                                <h5 className='parrafo-titulo' style={{ fontSize: '14px' }}>Por favor, crea tu contraseña para ingresar:</h5>
                            </div>
                        </div>
                    )}
                    {!isSocial && (
                        <>
                            <div className="form-item">
                                <div className="col text-left">
                                    <label className="form-label labelFont">Contraseña</label>
                                    <input type={typePwd1} className="form-pwd-control" value={firstPass} onChange={handlesetFirstPassChange} required />
                                    <span id="toggle_pwd" className={toggleClass} onClick={handlePasswordShow}></span><br />
                                    <ul class=" list-policy">
                                        <li class="list-policy-item"><i class="fa fa-check-circle check-circle"
                                            id="icon-8-characters" style={{ color: `${bg_8characters}` }}></i>Contener mínimo 8 caracteres</li>
                                        <li class="list-policy-item"><i class="fa fa-check-circle check-circle"
                                            id="icon-uppercase" style={{ color: `${bg_1upper}` }}></i>Incluir al menos una mayúscula (A-Z)</li>
                                        <li class="list-policy-item"><i class="fa fa-check-circle check-circle"
                                            id="icon-lowercase" style={{ color: `${bg_1lower}` }}></i>Incluir al menos una minúscula (a-z)</li>
                                        <li class="list-policy-item"><i class="fa fa-check-circle check-circle"
                                            id="icon-number" style={{ color: `${bg_1digit}` }}></i>Incluir
                                            al menos un número (0-9)</li>
                                    </ul>
                                    {errPass && (<span className="error" style={{ marginTop: '-30px', position: 'absolute' }}>Este campo no cumple con los requisitos</span>)}
                                </div>
                            </div>

                        </>
                    )}

                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev" id="btn-next" type='button' onClick={handlesetSubmitChange} disabled={
                                !(
                                    (name) &&
                                    (surname) &&
                                    (seconLastName) &&
                                    (curp) &&
                                    (rfc) &&
                                    (folio) &&
                                    (typeof birthDate !== 'undefined' ? birthDate.length >= 14 ? birthDate : '' : '') &&
                                    (isSocial == false ? firstPass != '' ? firstPass : '' : 'true')
                                )
                            }
                                style={{
                                    width: '100%', marginLeft: '0px', borderColor: '#3db9ac', background: '#3db9ac', borderRadius: '10px',
                                    height: '50px'
                                }}
                            >Siguiente</button>
                        </div>
                    </div>
                </form>
            )}
            {isLoading && (<Loading />)}
            <Terminos showTerms={terms} setTerms={setTerms} url={url} />

        </>
    );
}

export default FormularioRegistro;

