import React from 'react';
import './css/Registro.css';
import Navbar from './Navbar';
import FormularioTarjetaFisica from './FormularioTarjetaFisica'
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";

const VinculacionTarjeta = () => {

    const [searchParams] = useSearchParams();


    const { isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();

    if (!isAuthenticated) {
        return <div>Access denied {isAuthenticated}</div>;
    }

    return (

        <div>
            <Navbar title="Registro" cls1="hr_green" cls2="hr_gray" cls3="hr_gray" />
            <div className="col-xs-5 col-sm-5 col-sm-offset-5 login-box content">
                <FormularioTarjetaFisica {...user} />
            </div>
            <div>
            </div>

        </div>
    );
}

export default withAuthenticationRequired(VinculacionTarjeta, {
    onRedirecting: () => <Loading />,
});
