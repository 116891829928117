import React, { useState } from 'react';
import './css/Terminos.css'
import { IoMdClose } from 'react-icons/io';
import Iframe from 'react-iframe'


function Terminos({ showTerms, setTerms, url }) {

  return (
    <>
      {showTerms &&
        <div className='modal-terms'>
          <div className='modal-contenido-terms'>         
           
            <div className='contenido-terms'>
            <Iframe src={url} width="100%" height='100%' frameBorder={0}></Iframe>   
            </div> 
            <div className='modal-button-terms' onClick={() => setTerms(!showTerms)}>
              <IoMdClose size={20} />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Terminos;
