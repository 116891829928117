import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import ResultadoQR from './ResultadoQR'
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import './css/Registro.css';
import './css/Navbar.css';

const Registro = () => {

    const { isAuthenticated, user } = useAuth0();
    const location = useLocation();

    if (!isAuthenticated) {
        return <div>Access denied</div>;
    }
    let title = '';
    try {
        title = location.state.nav_title;
    } catch (err) {
        title = 'Cuenta exitosa';
    }

    return (
        <div>
             <Navbar title="Datos personales" cls1="hr_gray" cls2="hr_green" cls3="hr_gray"/>
             <div className="col-xs-5 col-sm-5 col-sm-offset-5 login-box content">
                <ResultadoQR {...user} />
            </div>
            <div>
            </div>
        </div>
    );
}

export default withAuthenticationRequired(Registro, {
    onRedirecting: () => <Loading />,
});
