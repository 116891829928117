import configJson from "./auth_config.json";
import jwt_decode from "jwt-decode";


export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
    api_key: configJson.geo_api,
    id: configJson.nav_id,
    service: configJson.servicesDomain,
    user_api: configJson.userAudience
  };
}


export async function validateToken(token, sub) {
  try {
    var decoded = jwt_decode(token);
    const exp = decoded.exp;
    const user = decoded.sub;

    const dt = new Date();
    dt.setMinutes(dt.getMinutes() + 5);

    if (dt.getTime() >= exp * 1000) {
      console.log(Date.now());
      console.log(exp * 1000);
      return false;
    } else {
      if (sub != user) {
        return false;
      }
      return true;
    }
  } catch (e) {

  }

}
