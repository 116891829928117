import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Terminos from './Terms';
import Loading from './Loading';
import { getConfig, validateToken } from "../config";
import './css/Formulario.css';
import './css/Switch.css';

function FormularioTarjetaFisica(user) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const { audience, service } = getConfig();

    const [isLoading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();


    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    }


    const actividades_options = [
        { value: 'Servicios', text: 'Servicios' }

    ];
    const [actividad, setActividad] = useState(actividades_options[0].value);
    const handleActividadChange = ({ target: { value } }) => setActividad(value);


    const job_options = [
        { value: 'Alquiler de equipo', text: 'Alquiler de equipo' },
        { value: 'BAR-CANTINA', text: 'BAR - CANTINA' },
        { value: 'CAFETERIA', text: 'CAFETERIA' },
        { value: 'VENTA EQUIPO', text: 'VENTA EQUIPO' }

    ];
    const [job, setJob] = useState(job_options[0].value);
    const handleJobChange = ({ target: { value } }) => setJob(value);


    const [amount, setAmount] = useState();
    const handleAmountChange = ({ target: { value } }) => setAmount(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));

    const [operations, setOperations] = useState();
    const handleOperationsChange = ({ target: { value } }) => setOperations(value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ´ 0-9]/gi, ''));


    // Validar si el login se hizo desde red social o no
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const jwt = queryParameters.get("session_token");
        var decoded = jwt_decode(jwt);

    }, []);

    const handlesetSubmitChange = async (event) => {
        event.preventDefault();

        const isValid = await validateForm();


        if (isValid == true) {
            setLoading(true);
            navigate(`/vinculacion?${searchParams.toString()}`, {
                state: {
                    nombre: `${location.state.nombre}`, paterno: `${location.state.paterno}`, materno: `${location.state.materno}`, curp: `${location.state.curp}`,
                    rfc: `${location.state.rfc}`, folio: `${location.state.folio}`, birthdate: `${location.state.birthdate}`, country: `${location.state.country}`,
                    civil: `${location.state.civil}`, gender: `${location.state.gender}`, password: `${location.state.password}`, calle: `${location.state.calle}`,
                    exterior: `${location.state.exterior}`, interior: `${location.state.interior}`, referencia: `${location.state.referencia}`, strategy: `${location.state.strategy}`,
                    zipcode: `${location.state.zipcode}`, colonia: `${location.state.colonia}`, municipio: `${location.state.municipio}`, estado: `${location.state.estado}`
                }
            });
        } else {
            setLoading(false);
        }
    }




    const validateForm = () => {


        return true;
    }


    return (
        <>
            {!isLoading && (
                <form>
                    <div className="form-item">
                        <div className="col text-left">
                            <h5>Prevención de lavado de dinero</h5>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>Actuó por cuenta propia y el dinero que reciba en esta cuenta es mío y no de alguna otra persona.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>Declaro que el dinero que yo disponga y que genere esta cuenta proviene y será usado en fuentes legales.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>Solamente yo podré disponer del dinero y beneficios que genere este contrato.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>No he ocupado un cargo público actualmente, ni en los últimos 12 meses.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>No soy familiar de alguien que ocupe o haya ocupado, en los últimos 12 meses algún cargo público.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>No tengo residencia en el extranjero, no soy accionista de alguna empresa extranjera y no pago impuestos en ningún otro país.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>Confirmo que todos mis datos registrados son correctos y actuales, autorizo su verificación.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">Tipo de actividad económica</label>
                            <select data-width="100%" className="form-control selector-wrapper" value={actividad} onChange={handleActividadChange} >
                                {actividades_options.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                            <span className="error" id="error-messages1"></span>
                        </div>
                    </div>



                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">¿Cúal es su actividad económica?</label>
                            <select data-width="100%" className="form-control selector-wrapper" value={job} onChange={handleJobChange} >
                                {job_options.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                            <span className="error" id="error-messages1"></span>
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="col text-left prevencion-item">
                            <span style={{ width: '80%', maxWidth: '80%', fontSize: '12px' }}>Confirmo que mis ingresos mensuales provienen exclusivamente del desempeño de mi ocupación, profesión o actividad manifiesta.</span>
                            <input type='checkbox' className='prevencion-check' style={{
                                left: '90%', top: "60px", float: 'right', position: 'absolute',
                                accentColor: '#b8be14', border: 'none', width: '20px', minWidth: '20px', maxWidth: '20px', maxHeight: '20px'
                            }} checked={true}></input>
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">¿Cúal es el monto de efectivo estimado que operará en esta cuenta?</label>
                            <input type='text' inputMode='numeric' pattern='[0-9 .]{6}' max={999999} maxLength={5}
                                className="form-control" value={amount} onChange={handleAmountChange} />
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <label className="form-label labelFont">¿Cúal es el número de operaciones mensuales estimadas que operará en esta cuenta?</label>
                            <input type='text' inputMode='numeric' pattern='[0-9]{6}' max={999999} maxLength={5}
                                className="form-control" value={operations} onChange={handleOperationsChange} />
                        </div>
                    </div>


                    <div className="form-item">
                        <div className="col text-left">
                            <button className="prev" id="btn-next" type='button' onClick={handlesetSubmitChange} disabled={
                                !(
                                    (amount) &&
                                    (operations)
                                )
                            }>Aceptar</button>
                        </div>
                    </div>
                </form>
            )}
            {isLoading && (<Loading />)}

        </>
    );
}

export default FormularioTarjetaFisica;

