import React, { useState } from 'react';
import '../css/Modales.css'
import { IoMdClose } from 'react-icons/io';
import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getConfig, validateToken } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Loading from '../Loading';

function Modal({ showModal, setModal }) {
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();

  //Get properties from .json
  const config = getConfig();
  console.log(user);


  const getToken = async () => {
    var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

    if (token == null) {
      const token = await getAccessTokenSilently({
        audience: config.audience
      });
      window.localStorage.setItem('token', token);
    } else {
      const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
      //console.log(isTokenValid);
      if (!isTokenValid) {

        const token = await getAccessTokenSilently({
          audience: config.audience
        });
        window.localStorage.setItem('token', token);
      }
    }
  }



  const handleContinuar = async () => {
    setLoading(true)
    //Regresar a la callback, se deniega el acceso y se hace un continue
    const state = searchParams.get('state');
    const session_token = searchParams.get('session_token');
    var decoded = jwt_decode(session_token);
    const user_id = decoded.sub;

    await getToken();

    //Consumir API
    const data = JSON.stringify({
      "iss": window.location.origin,
      "sub": `${user_id}`,
      "state": `${state}`,
      "status": "aborted",
      "message": `El usuario aborto la operacion`
    });

    //console.log(config.service);
    const axios_config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://${config.service}/members/cc/user/token/sign`,
      headers: {
        'Authorization': `${window.localStorage.getItem('token')}`,
        'team-ia': 'team-ia',
        'platform': window.localStorage.getItem('platform'),
        'Content-Type': 'application/json'
      },
      data: data
    };


    //Return -> Origin
    await axios.request(axios_config)
      .then((response) => {

        const jwt = response.data.token;

        window.localStorage.removeItem('attempts_otp');
        window.localStorage.clear();
        window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${jwt}`);
      })
      .catch((error) => {
        setLoading(false)
      });
  }

  return (
    <>
      {!isLoading && (
        <>
          {showModal &&
            <div className='fondo'>
              <div className='modal-abort'>
                <div className='modal-contenido'>
                  <div className='contenido'>
                    <h5 className='abort-title'>¿Estás seguro de que deseas abandonar el proceso?</h5>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-blue" id="btn-next" type='button' onClick={() => setModal(!showModal)}>Regresar</button>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-white" id="btn-next" type='button' onClick={handleContinuar}>Sí, deseo abandonar</button>
                      </div>
                    </div>
                  </div>
                  <div className='modal-button' onClick={() => setModal(!showModal)}>
                    <IoMdClose size={20} />
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
      {isLoading && (<Loading />)}
    </>
  );
}

export default Modal;
