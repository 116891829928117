import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
  <div className="fondo-loader">
    <div class="loader">
      <div class="loader-wheel"></div>
    </div>
  </div>
);

export default Loading;

/**
 * 
 * <div className="spinner">
      <img src={loading} alt="Loading" />
    </div>
 */
