import React, { useState } from 'react';
import '../css/Modales.css'

import { getConfig, validateToken } from "../../config";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Loading from "../Loading";
import { useAuth0 } from "@auth0/auth0-react";

function Modal({ showModal, setModal }) {
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();

  //Get properties from .json
  const config = getConfig();

  const handleContinuar = async () => {
    //Regresar a Auth0 firmando el JWT con el bloqueo de la cuenta
    //Redirect to Auth0 with JWT
    setLoading(true);
    window.localStorage.removeItem('attempts_otp');

    const state = searchParams.get('state');
    const session_token = searchParams.get('session_token');
    var decoded = jwt_decode(session_token);
    const user_id = decoded.sub;
    //Consumir API

    let data = JSON.stringify({
      "status": "blocked"
    });

    let config_axios = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://dcmacp1mej.execute-api.us-east-1.amazonaws.com/api/sign?${searchParams.toString()}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config_axios)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const session_token = response.data.session_token;
        const state = response.data.state;
        window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${session_token}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      {!isLoading && (
        <>
          {showModal &&
            <div className='fondo'>
              <div className='modal'>
                <div className='modal-contenido'>
                  <div className='contenido'>
                    <h5>Has excedido el número de intentos para validar tu teléfono.</h5>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-blue" id="btn-next" type='button' onClick={handleContinuar} >Entendido</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
      {isLoading && (<Loading />)}
    </>
  );
}

export default Modal;
