import React, { useState } from 'react';
import '../css/Modales.css'
import { IoMdClose } from 'react-icons/io';
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { getConfig, validateToken } from "../../config";
import jwt_decode from "jwt-decode";
import Loading from "../Loading";
import { useAuth0 } from "@auth0/auth0-react";

function Modal({ showModal, setModal, tel }) {
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  //Get properties from .json
  const config = getConfig();

  const { getAccessTokenSilently, user } = useAuth0();


  const getToken = async () => {
    var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

    if (token == null) {
      const token = await getAccessTokenSilently({
        audience: config.audience
      });
      window.localStorage.setItem('token', token);
    } else {
      const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
      //console.log(isTokenValid);
      if (!isTokenValid) {

        const token = await getAccessTokenSilently({
          audience: config.audience
        });
        window.localStorage.setItem('token', token);
      }
    }
  }


  const handleContinuar = async () => {
    //Regresar al callback
    setLoading(true);
    const state = searchParams.get('state');
    const session_token = searchParams.get('session_token');
    var decoded = jwt_decode(session_token);
    const user_id = decoded.sub;

    await getToken();
    //Consumir API
    const data = JSON.stringify({
      "iss": window.location.origin,
      "sub": `${user_id}`,
      "state": `${state}`,
      "status": "aborted",
      "message": "Proceso abortado"
    });

    const axios_config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://${config.service}/members/cc/user/token/sign`,
      headers: {
        'Authorization': `${window.localStorage.getItem('token')}`,
        'team-ia': 'team-ia',
        'platform': window.localStorage.getItem('platform'),
        'Content-Type': 'application/json'
      },
      data: data
    };


    //Return -> Origin
    await axios.request(axios_config)
      .then((response) => {

        const jwt = response.data.token;
        //const jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJodHRwOi8vbG9jYWxob3N0OjMwMDAvIiwiaXNzIjoiZ29vZ2xlLW9hdXRoMnwxMDExODMzOTkxOTEwMjcwNDk2ODUiLCJzdGF0ZSI6ImhLRm8yU0JyTjBGdVZ6ZGlTRFJ6TW5oUmJubFBSWGxMU0RObFFtbGZObGxJTFhBNE5xRnVxSEpsWkdseVpXTjBvM1JwWk5rZ1l6WnlXRWhsTkZkcVJsUmtOVlZrYVdoNFMzSmhUVkZzY21GRmMwVnVkSHFqWTJsazJTQjNXVWRHZHpWYVlYQnZNazl5WnpsdGRuWjJWVFZETkhabVkxQjZSMUp5Y3ciLCJpYXQiOjE2ODQ2MjY4MTksImV4cCI6MTY4NDYyODMxOSwib3RoZXIiOnsidGVsZXBob25lTnVtYmVyIjoiMjIyIDIyMiAyNTI1IiwidmluY3VsYWNpb24iOnRydWV9fQ.atYGRypiscHFWvq7HAmF_a1DLY1N9arDGvOYQafUPbM';

        window.localStorage.clear();
        window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${jwt}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <>


      {!isLoading && (
        <>
          {showModal &&
            <div className='fondo'>
              <div className='modal'>
                <div className='modal-contenido'>
                  <div className='contenido'>
                    <div className="form-item">
                      <div className="col">
                        <h5>
                          Ya existe una cuenta con el teléfono:
                          <br />** **** {tel.substring(7)}
                        </h5>
                      </div></div>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-blue" id="btn-next" type='button' onClick={handleContinuar} >Iniciar sesión</button>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="col text-left">
                        <button className="btn-white" id="btn-next" type='button' onClick={() => setModal(!showModal)}>Intentar de nuevo</button>
                      </div>
                    </div>
                    <h6>¿Tienes algún problema?
                      <br />Envíanos un correo a: <a href="mailto:clubcinepolis@cinepolis.com">clubcinepolis@cinepolis.com</a></h6>
                  </div>
                  <div className='modal-button' onClick={() => setModal(!showModal)}>
                    <IoMdClose size={20} />
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
      {isLoading && (<Loading />)}
    </>
  );
}

export default Modal;
