import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { getConfig, validateToken } from "../config";
import './css/Formulario.css';
import Modal from './modales/ModalEnroll';
import ModalLimit from './modales/ModalLimitAttemps';
import Loading from "./Loading";

function FormularioTarjetaFisica(user) {

    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [isLoading, setLoading] = useState(false);
    //Get properties from .json
    const config = getConfig();
    const { getAccessTokenSilently } = useAuth0();

    //Codigo ingresado por el usuario
    const [codigoOtp, setOTP] = useState('');
    var attempts_otp;
    try {
        attempts_otp = window.localStorage.getItem('attempts_otp') != 'undefined' ? window.localStorage.getItem('attempts_otp') : 3;
    } catch (e) {

    }
    const [count, setCount] = useState(attempts_otp != null ? attempts_otp : 3); //Contador de intentos para validar el OTP


    //Btn reenviar el codigo
    const [resendSMS, setResendSMS] = useState(false);
    const [validarOtp, setValidarOTP] = useState(true);
    const [enableComponents, setEnableComponents] = useState(false);




    const getToken = async () => {
        var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

        if (token == null) {
            const token = await getAccessTokenSilently({
                audience: config.audience
            });
            window.localStorage.setItem('token', token);
        } else {
            const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
            //console.log(isTokenValid);
            if (!isTokenValid) {

                const token = await getAccessTokenSilently({
                    audience: config.audience
                });
                window.localStorage.setItem('token', token);
            }
        }
    }


    const handleChangecodigoOtp = ({ target: { value } }) => {
        validateForm(value);
        setOTP(value.replace(/[^0-9]/gi, ''));
    };


    //Countdown ---------------------------------------------------------------
    const [countDown, setCountDown] = useState(239);
    useEffect(() => {

        if (attempts_otp != 0) {
            countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
            countDown == 0 && count > 0 ? setResendSMS(true) : setResendSMS(false);
        } else {
            setCountDown(0);
            setEnableComponents(true);
            setResendSMS(false);
            setValidarOTP(true);
            setModalLimit(true);
        }
    }, [countDown]);

    const resend = () => {
        countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
        countDown == 0 && count > 0 ? setResendSMS(true) : setResendSMS(false);
    };
    //------------------------------------------------------------------------



    //Manejo de modal
    const [modal, setModal] = useState(false);
    const handleShowModal = () => setModal(!modal);

    //Manejo de modal limite superado
    const [modalLimit, setModalLimit] = useState(false);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && codigoOtp.length >= 6) {
            handleValidateOTP(event);
        }
    };


    const handlesetResendSMS = async (event) => {
        event.preventDefault();
        setLoading(true);
        //Enviar OTP
        let data = JSON.stringify({
            "client_id": config.id,
            "connection": "sms",
            "phone_number": `+52${location.state.telephone.toString().replaceAll(' ', '')}`,
            "send": "code"
        });

        let axios_config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${config.domain}/passwordless/start`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(axios_config)
            .then((response) => {
                setLoading(false);
                setCountDown(239);
                resend();
            })
            .catch((error) => {
                setLoading(false);
            });

    }

    const handleValidateOTP = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (count > 0) {
            window.localStorage.setItem('attempts_otp', count - 1);
            setCount(count - 1);

            const data = JSON.stringify({
                "grant_type": "http://auth0.com/oauth/grant-type/passwordless/otp",
                "client_id": config.id,
                "otp": `${codigoOtp}`,
                "realm": "sms",
                "username": `+52${location.state.telephone.toString().replaceAll(' ', '')}`
            });


            const config_axios = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://${config.domain}/oauth/token`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            await axios.request(config_axios)
                .then(async (response) => {

                    if (JSON.stringify(response.status) !== "200") {
                        //Obtener token del usuario
                        setModal(true);

                    } else {
                        //Redirect to Auth0 with JWT
                        window.localStorage.removeItem('attempts_otp');

                        const state = searchParams.get('state');
                        await getToken();
                        //Consumir API

                        let data = JSON.stringify({
                            "status": "success",
                            "phone_number": `+52${location.state.telephone.toString().replaceAll(' ', '')}`
                        });

                        let config_axios = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `https://dcmacp1mej.execute-api.us-east-1.amazonaws.com/api/sign?${searchParams.toString()}`,
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        axios.request(config_axios)
                            .then((response) => {
                                console.log(JSON.stringify(response.data));
                                const session_token = response.data.session_token;
                                const state = response.data.state;
                                window.localStorage.clear();
                                window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${session_token}`);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (count > 1)
                        setModal(true);
                    setOTP('');
                    setValidarOTP(true);

                });
        } else {
            setLoading(false);
            window.localStorage.setItem('attempts_otp', 0);
            setResendSMS(false);
            setValidarOTP(true);
            setEnableComponents(true);
            setModalLimit(true);
        }

        //
    }

    function cc_format(value) {
        if (value > 180) {
            if (value - 180 > 9) {
                return `03:${value - 180}`;
            } else {
                return `03:0${value - 180}`;
            }
        } else if (value > 120) {
            if (value == 180) {
                return `03:00`;
            }
            if (value - 120 > 9) {
                return `02:${value - 120}`;
            } else {
                return `02:0${value - 120}`;
            }
        } else if (value >= 60) {
            if (value == 120) {
                return `02:00`;
            }
            if (value - 60 > 9) {
                return `01:${value - 60}`;
            } else {
                return `01:0${value - 60}`;
            }
        } else {
            if (value > 9) {
                return `00:${value}`;
            } else {
                return `00:0${value}`;
            }
        }
    }

    const validateForm = (value) => {
        setValidarOTP(true);
        if (value.length >= 6) {
            setValidarOTP(false);
        }
    }


    return (
        <>
            {!isLoading && (
                <form>
                    <h5 style={{ color: '#3e2669', fontSize: '20px' }}>Escribe el código que recibiste por SMS para autorizar la operación</h5>
                    <br />    <br />  <br />
                    <div className="row div-timer">
                        <div className="col text-left div-timer">
                            <div className="input-group">
                                <div id="myTimer" className='myTimer' style={{ fontSize: '24px' }}>{cc_format(countDown)}</div>
                            </div>
                        </div>
                    </div>

                    <input maxLength={6} value={codigoOtp} id="codigo"
                        type='text'
                        inputMode='numeric'
                        pattern='[0-9]{6}'
                        max={999999}
                        onChange={handleChangecodigoOtp}
                        onKeyDown={handleKeyDown}
                        autoFocus disabled={enableComponents} />

                    <div className="row form-item" style={{ marginTop: '50px' }}>
                        <div className="col text-left">
                            <button className="prev-white" id="btn-next" type='button'
                                onClick={handlesetResendSMS}
                                disabled={!resendSMS}>Reenviar código</button>
                        </div>
                    </div>
                    <div className="form-item div-btn-primary" style={{ marginTop: '10px' }}>
                        <div className="col text-left">
                            <button className="prev" id="btn-next"
                                type='button'
                                onClick={handleValidateOTP}
                                disabled={validarOtp}>Verificar mi teléfono</button>
                        </div>
                    </div>
                </form>
            )}
            {isLoading && (<Loading />)}
            <Modal showModal={modal} setModal={setModal} />
            <ModalLimit showModal={modalLimit} setModal={setModalLimit} />
        </>
    );
}




export default FormularioTarjetaFisica;

