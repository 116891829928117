import React from 'react';
import './css/Registro.css';
import Navbar from './Navbar';
import FormularioRegistro from './FormularioRegistro'
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";


const Registro = () => {

    const { isAuthenticated, user } = useAuth0();
   

    if (!isAuthenticated) {
        return <div>Access denied</div>;
    }
    
    return (
        <div>
            <Navbar title="Datos personales" cls1="hr_gray" cls2="hr_green" cls3="hr_gray"/>
            <div className="col-xs-5 col-sm-5 col-sm-offset-5 login-box content">
                <FormularioRegistro {...user}/>
            </div>
            <div>
            </div>     
        </div>
    );
}

export default withAuthenticationRequired(Registro, {
    onRedirecting: () => <Loading />,
});
