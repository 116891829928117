import React, { useEffect, useState } from 'react';
import './css/Registro.css';
import Navbar from './Navbar';
import Home from './Inicio'
import Loading from "./Loading";
import jwt_decode from "jwt-decode";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getConfig, validateToken } from "../config";

const Inicio = () => {

    //Get properties from .json
    const config = getConfig();
    const [searchParams] = useSearchParams();
    const { getAccessTokenSilently } = useAuth0();

    //console.log(searchParams.toString());
    const { isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();

    console.log('token----' + user.sub);

    useEffect(() => {
        try {
            getToken();


            var decoded = jwt_decode(searchParams.get('session_token'));
            const platform = decoded.platform;
            const method = decoded.method;
            window.localStorage.setItem('platform', platform);


            if (user.tcc != null) {
                navigate(`/vinculacion?${searchParams.toString()}`, {
                    state: {
                        tcc: user.tcc,
                        email: user.email
                    }
                });
            } else if (method != null && method == 'linkup') {
                var attempts_card;
                try {
                    attempts_card = window.localStorage.getItem('attempts_tcc') != 'undefined' ? window.localStorage.getItem('attempts_tcc') : null;
                    if (attempts_card == null) {
                        window.localStorage.setItem('attempts_tcc', 2);
                    }
                    navigate(`/vincula-tcc?${searchParams.toString()}`, {
                        state: {
                            email: user.email
                        }
                    });
                } catch (e) {

                }

            } else if (method != null && method == 'register') {
                navigate(`/registro?${searchParams.toString()}`, {
                    state: {
                        email: user.email
                    }
                });
            }


        } catch (error) {

        }
    });

    const getToken = async () => {
        var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

        if (token == null) {
            const token = await getAccessTokenSilently({
                audience: config.audience
            });
            window.localStorage.setItem('token', token);
        } else {
            const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
            //console.log(isTokenValid);
            if (!isTokenValid) {
                const token = await getAccessTokenSilently({
                    audience: config.audience
                });
                window.localStorage.setItem('token', token);
            }
        }
    }

    if (!isAuthenticated) {
        return <div>Access denied {isAuthenticated}</div>;
    }

    return (

        <div>
            <Navbar title="Registro" cls1="hr_green" cls2="hr_gray" cls3="hr_gray" />
            <div className="col-xs-5 col-sm-5 col-sm-offset-5 login-box content">
                <Home {...user} />
            </div>
            <div>
            </div>
        </div>
    );
}

export default withAuthenticationRequired(Inicio, {
    onRedirecting: () => <Loading />,
});
