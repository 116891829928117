import React from 'react';
import './css/Registro.css';
import Navbar from './Navbar';
import FormularioVinculacion from './FormularioVinculacion'
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";


const Vinculacion = () => {
    const { isAuthenticated, user } = useAuth0();
    if (!isAuthenticated) {
        return <div>Access denied {isAuthenticated}</div>;
    }

    return (
        <div>
            <Navbar  title="Datos personales" cls1="hr_gray" cls2="hr_green" cls3="hr_gray"/>
            <div className="col-xs-12 col-sm-5 col-sm-offset-5 login-box content">
                <FormularioVinculacion {...user} />
            </div>
        </div>
    );
}

export default withAuthenticationRequired(Vinculacion, {
    onRedirecting: () => <Loading />,
});