//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React from 'react';
import Loading from "./components/Loading";
import Registro from './components/Registro';
import Home from './components/Home';
import Vinculacion from './components/Vinculacion';
import QR from './components/QR';
import TarjetaFisica from './components/TarjetaFisica';
import EnrollTelefono from './components/EnrollTelephone';
import ValidacionOtp from './components/ValidaOtp';
import FailedIntent from './components/FailedIntent';
import FailedAttempsLimitTCC from './components/FailedIntentThree';
import FailedCompareStrings from './components/FailedDatos';

import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import './App.css';


function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/registro' element={<Registro />} />
          <Route path='/vinculacion' element={<Vinculacion />} />
          <Route path='/cuenta-creada' element={<QR />} />
          <Route path='/vincula-tcc' element={<TarjetaFisica />} />
          <Route path='/registro-telefono' element={<EnrollTelefono />} />
          <Route path='/confirma-telefono' element={<ValidacionOtp />} />
          <Route path='/error' element={<FailedIntent />} />
          <Route path='/error-attemps-limit' element={<FailedAttempsLimitTCC />} />
          <Route path='/error1' element={<FailedCompareStrings />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
