import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from "./Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { getConfig, validateToken } from "../config";


//import './css/FailedIntentThree.css';
import './css/Navbar.css';


function FailedAttemps() {
    const config = getConfig();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();



    const getToken = async () => {
        var token = window.localStorage.getItem('token') != 'undefined' ? window.localStorage.getItem('token') : null;

        if (token == null) {
            const token = await getAccessTokenSilently({
                audience: config.audience
            });
            window.localStorage.setItem('token', token);
        } else {
            const isTokenValid = await validateToken(window.localStorage.getItem('token'), user.sub);
            console.log(isTokenValid);
            if (!isTokenValid) {

                const token = await getAccessTokenSilently({
                    audience: config.audience
                });
                window.localStorage.setItem('token', token);
            }
        }
    }


    const handleCreateAccount = (event) => {
        event.preventDefault();
        navigate(`/registro?${searchParams.toString()}`);
    }

    const handleReturn = async (event) => {
        event.preventDefault();
        setLoading(true);
        const state = searchParams.get('state');
        const session_token = searchParams.get('session_token');
        var decoded = jwt_decode(session_token);
        const user_id = decoded.sub;

        await getToken();
        //Consumir API
        const data = JSON.stringify({
            "iss": window.location.origin,
            "sub": `${user_id}`,
            "state": `${state}`,
            "status": "aborted",
            "message": `Proceso cancelado por el usuario`
        });

        const axios_config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${config.service}/members/cc/user/token/sign`,
            headers: {
                'Authorization': `${window.localStorage.getItem('token')}`,
                'team-ia': 'team-ia',
                'platform': window.localStorage.getItem('platform'),
                'Content-Type': 'application/json'
            },
            data: data
        };

        //Return -> Origin
        await axios.request(axios_config)
            .then((response) => {
                const jwt = response.data.token;
                const state = searchParams.get('state');
                window.localStorage.clear();
                window.location.replace(`https://${config.domain}/continue?state=${state}&session_token=${jwt}`);
            })
            .catch((error) => {
                setLoading(false);

            });


    }




    const { isAuthenticated, user } = useAuth0();


    if (!isAuthenticated) {
        return <div>Access denied</div>;
    }

    return (
        <>
            {!isLoading && (
                <>
                    <Container className='navbar' fluid>
                        <Row>
                            <Col> <img className='logo' /></Col>
                        </Row>
                    </Container>

                    <div className="col-xs-5 col-sm-5 col-sm-offset-5 cards">
                        <div className="card gray">
                            <img className='logo-club' />
                            <h5 className='warning'>¡Ups!</h5>
                        </div>
                        <div className="card contenido">
                            <h4 className='title-small'>Tus datos no han podido ser encontrados</h4>
                            <p className='description'>Por favor crea una cuenta nueva.</p>
                            <div className="form-item">
                                <div className="col text-left">
                                    <button className="prev" id="btn-next" type='button' onClick={handleCreateAccount}>Crear cuenta nueva</button>
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="col text-left">
                                    <button className="prev-white" id="btn-next" type='button' onClick={handleReturn}>Ir al inicio</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {isLoading && (<Loading />)}
        </>
    );
}

export default withAuthenticationRequired(FailedAttemps, {
    onRedirecting: () => <Loading />,
});

