import React, { useState } from 'react';
import './css/Registro.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import FormularioOtp from './FormularioValidaOTP';
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "./modales/ModalAbortOperation";

const ValidacionOtp = () => {

    const [searchParams] = useSearchParams();


    const { isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();


    const [show, setShowModal] = useState(false);


    if (!isAuthenticated) {
        return <div>Access denied {isAuthenticated}</div>;
    }

    return (

        <div>
            <Container className='navbar' fluid>
                <Row>
                    <Col> <img className='logo' /></Col>
                </Row>
                <Container className='navbar_menubar'>
                    <Row>
                        <Col className='navbar_menubar_first'><IoIosArrowBack size={20} onClick={() => navigate(-1)} /></Col>
                        <Col className='navbar_menubar_middle' xs={6}>Iniciar sesión</Col>
                        <Col className='navbar_menubar_last'><IoMdClose size={20} onClick={() => { setShowModal(!show) }} /></Col>
                    </Row>
                </Container>
            </Container>
            <div className="col-xs-5 col-sm-5 col-sm-offset-5 login-box content" style={{borderRadius: '10px', width: '90%'}}>
                <FormularioOtp {...user} />
            </div>
            <div>
            </div>
            <Modal showModal={show} setModal={setShowModal} />
        </div>
    );
}

export default withAuthenticationRequired(ValidacionOtp, {
    onRedirecting: () => <Loading />,
});
